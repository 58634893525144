<template>
  <div>
    <div
      v-if="isLoaded"
      id="intro_wishlist_items"
      :class="{ 'disable-whole-div': isPerformingAction }"
    >
      <div class="d-flex flex-row">
        <template v-if="Object.keys(filterOptions).length > 2">
          <!-- Sidebar -->
          <div class="wishlist-sidebar side-bar">
            <shop-filter
              v-if="filterOptions.departments && filterOptions.departments.length"
              class="mr-1"
              :filters="filters"
              :total-products="totalProducts"
              :filter-options="filterOptions"
              :whole-sale-enable="isShowWsp"
              :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
              @update-filters="fetchShopProducts"
              @close-sidebar="closeSideBar"
            />
          </div>

          <!-- ECommerce Header -->
          <div class="pb-0 w-100">
            <div
              class="sticky-search-bar wishlist"
              :class="!isBrand && 'pb-0'"
            >
              <section id="ecommerce-header">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ecommerce-header-items">
                      <div class="result-toggler">
                        <feather-icon
                          icon="MenuIcon"
                          class="d-block d-lg-none mr-1"
                          size="21"
                          @click="mqShallShowLeftSidebar = true"
                        />
                        <div class="search-results d-block d-lg-none">
                          {{ totalProducts || 0 }} product{{ totalProducts > 1 ? 's' : '' }} found
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Overlay -->
              <div class="body-content-overlay" />

              <!-- Searchbar -->
              <div class="ecommerce-searchbar">
                <b-row class="wishlist-row ml-0 align-items-center">
                  <b-col class="pl-0 pr-0 col-12 col-md-4">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        v-model="filters.search"
                        placeholder="Search by products, style code..."
                        class="search-product pr-3"
                        @input="searchProducts"
                      />
                    </b-input-group>
                    <feather-icon
                      icon="SearchIcon"
                      size="18"
                      class="position-absolute search-icon"
                    />
                  </b-col>
                  <b-col class="pl-0 col-6 col-md-2">
                    <div
                      v-if="isRetailer && wishlist.availableCurrencies.length"
                      class="ml-md-1 mt-1 mt-md-0"
                    >
                      <currency-dropdown
                        :base-currency="wishlistBaseCurrency"
                        :selected-currency="filters.currency"
                        :currencies="wishlist.availableCurrencies || []"
                        @on-selected-currency="filterByCurrency"
                      />
                    </div>
                  </b-col>
                  <b-col class="col-12 pt-1 pt-md-0 col-md-6 d-flex justify-content-end pr-50">
                    <!-- Select all button -->
                    <div class="mr-1">
                      <select-all-button
                        :is-selected-all="selectCount === wishlist.count"
                        @toggle-select-all="onToggleSelectAll"
                      />
                    </div>
                    <b-button
                      v-if="isBrand && !isReadOnly"
                      v-b-modal="`modal-share-wishlist-${wishlistId}`"
                      class="mr-1 elements-center py-0 text-nowrap"
                      variant="success"
                      @click="onClickShareWishlist"
                    >
                      <rounded-share-icon class="share-seletion-icon" />
                      Share Selection
                    </b-button>
                    <b-button
                      class="custom-icon-btn"
                      :variant="itemViewClass === PRODUCTS_VIEW.GRID_VIEW ? 'outline-info' : 'outline-secondary'"
                      @click="onCompactToggleClick"
                    >
                      <LargeGridViewIcon />
                    </b-button>
                    <b-button
                      class="custom-icon-btn mr-1"
                      :variant="itemViewClass === PRODUCTS_VIEW.COMPACT_GRID_VIEW ? 'outline-info' : 'outline-secondary'"
                      @click="onCompactToggleClick"
                    >
                      <CompactGridViewIcon />
                    </b-button>
                    <div>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :text="selectedSortBy.text"
                        right
                        variant="outline-primary"
                        class="custom-dropdown-radius"
                      >
                        <b-dropdown-item
                          v-for="sortOption in sortByOptions"
                          :key="sortOption.value"
                          @click="selectedSortBy = sortOption"
                        >
                          {{ sortOption.text }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-col>
                  <b-col
                    v-if="!isBrand"
                    class="pl-0 col-12 mx-10-px align-items-center"
                  >
                    <span class="color-grey-sult font-size-16-px">Group Products by: </span>
                    <b-dropdown
                      variant="none"
                      class="group-dropdown"
                      no-caret
                    >
                      <template #button-content>
                        <span class="pr-2-px font-size-16-px"> {{ selectedGroup || 'None' }}</span>
                        <feather-icon
                          class="align-middle"
                          icon="ChevronDownIcon"
                          size="16"
                        />
                      </template>
                      <div class="heading">
                        Group By
                      </div>
                      <b-dropdown-item
                        v-for="group in groups"
                        :key="group"
                        :active="selectedGroup === group"
                        @click="selectedGroup = group"
                      >
                        {{ group }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- Wishlist Products -->
            <div
              v-if="products.length"
              class="shop-height"
              :class="!isBrand && 'wishlist-page'"
            >
              <div
                v-for="(groupedProduct, index) in products"
                :key="index"
              >
                <div
                  v-if="groupedProduct._id && groupedProduct.products.length"
                  class="group-heading wishlist"
                  :style="`z-index :${index + 2}`"
                >
                  <div class="name">
                    {{ groupedProduct._id }}
                  </div>
                </div>
                <section
                  v-if="groupedProduct.products.length"
                  class="wishlist-items"
                  :class="itemViewClass"
                >
                  <!-- has product -->
                  <template>
                    <b-card
                      v-for="product in groupedProduct.products"
                      :key="product.productInfo._id"
                      class="ecommerce-card ecom mb-2"
                      no-body
                    >
                      <span
                        v-if="product.productInfo.keyStyles"
                        class="text-capitalize table-status key-style"
                      >
                        {{ product.productInfo.keyStyles }}
                      </span>
                      <b-form-checkbox
                        v-if="isShowWsp && !isReadOnly"
                        :id="product._id"
                        v-model="indexes"
                        class="customCheck"
                        :class="show ? 'showCheckAll' : ''"
                        :value="product.productInfo._id"
                        @change="addToArray(product, index)"
                      />

                      <div class="item-img text-center pt-0">
                        <b-link
                          :class=" show ? 'pointerevents':'' "
                          @click="onClickWishlistProduct(product)"
                        >
                          <div class="card-square-image">
                            <b-card-img-lazy
                              class="bg-gray img img-responsive fullwidth"
                              :alt="`${product.productInfo.name}-${product.productInfo._id}`"
                              fluid-grow
                              :src="parseProductImage(product.productInfo)"
                              :class="`img-${product.productInfo._id}`"
                              @load.native="calculateImageRatio(`img-${product.productInfo._id}`)"
                              @error.native="imageLoadError"
                            />
                            <div class="product-color-wrapper">
                              <div
                                v-for="productColors in product.colors"
                                :key="productColors.color"
                                class="product-color-bg"
                              >
                                <div
                                  v-if="getColorCode(productColors.colorFamily)"
                                  v-b-tooltip.hover.left="productColors.color"
                                  class="product-color"
                                  :class="classForWhiteColor(productColors.colorFamily)"
                                  :style="'background:' + getColorCode(productColors.colorFamily)"
                                />
                              </div>
                            </div>
                          </div>
                        </b-link>
                      </div>

                      <!-- Product Details -->
                      <b-card-body class="card-body-padding">
                        <div class="product-name item-name d-flex">
                          <b-link
                            :id="`tooltip_w_product_name_${product.productInfo._id}`"
                            :class="show ? 'pointerevents' : ''"
                            class="text-left item-name pr-2"
                            :to="{ name: 'product-detail', params: { id: product.productInfo._id, collectionId: product.productInfo.collectionId } }"
                            @mouseover="showTooltip(`tooltip_w_product_name_${product.productInfo._id}`, product.productInfo._id)"
                          >
                            {{ product.productInfo.name }}
                          </b-link>
                          <b-tooltip
                            v-if="product.productInfo._id === tooltipId"
                            class="font-Inter"
                            :target="`tooltip_w_product_name_${product.productInfo._id}`"
                            placement="bottom"
                            triggers="hover"
                          >
                            {{ product.productInfo.name }}
                          </b-tooltip>
                        </div>
                        <div class="d-flex mb-5-px flex-wrap justify-content-between">
                          <b-card-text class="product-style-code">
                            <span class="style-code">Style Code:</span> {{ product.productInfo.styleCode }}
                          </b-card-text>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap text-nowrap">
                          <b-card-text
                            v-if="isShowWsp"
                            class="product-price d-flex flex-wrap"
                          >
                            WSP: <span
                              v-if="!product.productInfo.discountedPrice"
                              :class="{
                                'text-line-through': product.productInfo.discountedPrice,
                              }"
                            >
                              {{ formatCurrency(product.productInfo.price, product.productInfo.currency) }} </span>
                            <span
                              v-if="product.productInfo.discountedPrice"
                              class="discounted-price"
                            >
                              {{
                                formatCurrency(product.productInfo.discountedPrice, product.productInfo.currency)
                              }}
                            </span>
                          </b-card-text>
                          <b-card-text class="product-price-rrp mb-0">
                            RRP: {{ formatCurrency(product.productInfo.retailPrice, product.productInfo.currency) }}
                          </b-card-text>
                        </div>
                      </b-card-body>

                      <!-- Action Buttons -->
                      <div
                        v-if="isShowWsp && !isReadOnly"
                        class="item-options flex-wrap text-center"
                      >
                        <b-button
                          :class="show ? 'pointerevents' : ''"
                          variant="light"
                          class="btn-wishlist remove-wishlist"
                          @click="removeProductFromWishlistClick(product)"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="18"
                          />
                          <span>Remove</span>
                        </b-button>
                        <b-button
                          v-if="isRetailer"
                          :class="show ? 'pointerevents' : ''"
                          variant="none"
                          class="btn-cart button-cart move-cart text-nowrap"
                          @click="handleWishlistCartActionClick(product, wishlistId)"
                        >
                          <feather-icon
                            icon="ShoppingCartIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="compact-view-cart-text">{{ isProductInCart(product) ? 'In Cart' : '' }}</span>
                          <span class="large-view-cart-text"> {{ isProductInCart(product) ? 'View In Cart' : 'Move to Cart'
                          }}</span>
                        </b-button>
                      </div>
                    </b-card>
                  </template>
                </section>
              </div>
              <section
                v-if="continueLoading"
                class="mt-4"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="text-center"
                  >
                    <b-spinner
                      ref="loadingIcon"
                      class="loading-icon"
                      label="Loading..."
                    />
                  </b-col>
                </b-row>
              </section>
            </div>
            <div v-else>
              <empty-products-message v-if="filters.search" />
            </div>
          </div>
        </template>
        <template v-else>
          <b-card
            title="No Products Added"
            class="w-100"
          >
            <b-card-text>
              Go to "{{ isRetailer ? 'Explore' : 'Collections' }}" and start adding some products to your Amazing {{
                getWishlistTitle(isRetailer) }}
            </b-card-text>
            <div class="d-flex">
              <div id="explore_button">
                <b-button
                  class="explore-now"
                  :to="isRetailer ? { name: 'explore' } : { name: 'collections' }"
                  variant="primary"
                  :disabled="isRetailer && !isEnabledMarketplace"
                >
                  {{ isRetailer ? 'Explore Now' : 'Collections' }}
                </b-button>
              </div>
            </div>
            <b-tooltip
              v-if="isRetailer && !isEnabledMarketplace"
              target="explore_button"
              placement="bottom"
            >
              {{ marketplaceAccessDeniedMessage }}
            </b-tooltip>
          </b-card>
        </template>
      </div>
      <tool-kit :toolkit-page="TOOLKIT_PAGE.WISHLIST" />
    </div>
    <b-card
      v-else
      class="p-3 elements-center"
    >
      <b-spinner />
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BCardBody, BCardImgLazy, BCardText, BLink, BButton, BFormCheckbox, BSpinner,
  BRow, BCol, BInputGroup, BFormInput, VBTooltip, BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted, onUnmounted,
} from '@vue/composition-api'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@core/utils/toast'
import { getColorCode, classForWhiteColor } from '@/product-colors'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
  debounce,
  elementInViewport, formatObject, getDefaultProductPlaceholderImage, getWishlistTitle, isEllipsisActive,
} from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics, { getSelectionOrWishlistMixpanelObj } from '@/@core/utils/analytics'
import constants, {
  TOOLKIT_PAGE, wishlistAction, PRODUCTS_VIEW, PRODUCTS_LIMIT, marketplaceAccessDeniedMessage, DOWNLOAD_TYPES, FILE_FORMATS,
} from '@/constants'
import LargeGridViewIcon from '@/@core/assets/svg-components/LargeGridViewIcon.vue'
import CompactGridViewIcon from '@/@core/assets/svg-components/CompactGridViewIcon.vue'
import {
  FETCH_WISHLIST_FILTERS, FETCH_WISHLIST_PRODUCTS, UPDATE_CURRENT_CHECKOUT_WISHLIST, UPDATE_IS_SELECTED_ALL_PRODUCTS, UPDATE_SELECT_COUNT, UPDATE_SELECT_TYPE, MULTI_SELECT_PAGES,
  UPDATE_TOTAL_PRODUCTS_COUNT,
} from '@/store/modules/shop.module'
import RoundedShareIcon from '@/@core/assets/svg-components/RoundedShareIcon.vue'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'

import { mapState } from 'vuex'
import { DOWNLOAD_COLLECTION } from '@/store/modules/collection.module'
import Ripple from 'vue-ripple-directive'
import CurrencyDropdown from '@/views/components/currency-dropdown/CurrencyDropdown.vue'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import SelectAllButton from '@/views/components/select-all-btn/SelectAllButton.vue'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import ShopFilter from '../shop/ShopFilter.vue'
import EmptyProductsMessage from '../shop/EmptyProductsMessage.vue'
import UserRoleMixin from '../UserRoleMixin.vue'
import { useShopFiltersSortingAndPagination } from '../shop/useECommerceShop'

const { formatCurrency } = utils

const { BRAND_ACCESS } = c

const sortByOptions = useShopFiltersSortingAndPagination().sortByOptions

const { CONNECTION_REQUEST_STATUS, TRACKS } = constants

const { MULTI_SELECT_PRODUCTS } = TRACKS.ACTIONS
export default {
  name: 'Wishlist',
  components: {
    BCard,
    BCardBody,
    BCardImgLazy,
    BCardText,
    BLink,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ShopFilter,
    FeatherIcon,
    EmptyProductsMessage,
    LargeGridViewIcon,
    CompactGridViewIcon,
    RoundedShareIcon,
    CurrencyDropdown,
    SelectAllButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin, ProductImageMixin],
  props: {
    select: Boolean,
  },
  data() {
    return {
      show: false,
      productList: [],
      indexes: [],
      groupIndexes: [],
      selectall: 'false',
      tooltipId: '',
      getDefaultProductPlaceholderImage,
      TOOLKIT_PAGE,
      getColorCode,
      classForWhiteColor,
      getWishlistTitle,
      PRODUCTS_VIEW,
      itemViewClass: PRODUCTS_VIEW.GRID_VIEW,
      marketplaceAccessDeniedMessage,
      sortByOptions,
      selectedSortBy: sortByOptions[0],
    }
  },
  computed: {
    isShowWsp() {
      return !(this.isWspHidden && this.requestStatus !== CONNECTION_REQUEST_STATUS.APPROVED)
    },
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
  },
  watch: {
    products(val) {
      if (this.isSelectedAllProducts) {
        this.indexes = []
        val.forEach(groupedProduct => {
          groupedProduct.products.forEach(product => {
            this.indexes.push(product.productId)
          })
        })
        this.$store.commit(UPDATE_SELECT_COUNT, this.wishlist.count)
      }
    },
    selectedSortBy() {
      this.filters.sortBy = this.selectedSortBy.value
      this.fetchShopProducts()
    },
  },
  created() {
    this.$root.$refs.wishlist = this
    store.commit(UPDATE_SELECT_TYPE, MULTI_SELECT_PAGES.WISHLIST)
    this.filters.sortBy = this.selectedSortBy.value
    this.fetchShopProducts()
  },
  destroyed() {
    store.commit(UPDATE_SELECT_COUNT, 0)
    store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
    analytics.track(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_END_VIEWING_SELECTION : constants.TRACKS.ACTIONS.RETAILER_END_VIEWING_WISHLIST, formatObject(getSelectionOrWishlistMixpanelObj(this.wishlist, this.isBrand)))
  },
  methods: {
    onToggleSelectAll() {
      const isClickedSelectAll = this.selectCount !== this.wishlist?.count
      if (isClickedSelectAll) {
        analytics.track(MULTI_SELECT_PRODUCTS.USER_CLICKS_SELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE, { wishlist: this.wishlist })
      } else {
        analytics.track(MULTI_SELECT_PRODUCTS.USER_CLICKS_DESELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE, { wishlist: this.wishlist })
      }
      this.selectAll(isClickedSelectAll)
    },
    onClickShareWishlist() {
      this.$bvModal.show(`modal-share-wishlist-${this.wishlistId}`)
      analytics.track(constants.TRACKS.ACTIONS.BRAND_OPENS_SHARE_SELECTION_POPUP, { wishlist: this.wishlist })
    },
    isProductInCart(product = null) {
      return product?.isInCart || product?.productInfo?.isInCart
    },
    async downloadSelectedWishlistProducts() {
      try {
        const payload = {
          downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
          respectiveId: this.collectionId,
          fileType: FILE_FORMATS.XLSX,
          productIds: this.indexes,
        }
        const params = {
          isOrderSheetDownload: true,
        }
        const res = await this.$store.dispatch(DOWNLOAD_COLLECTION, { payload, params })
        apiToastSuccess(res.data.message)
        this.productList = []
        this.indexes = []
        this.show = false
        this.$store.commit(UPDATE_SELECT_COUNT, 0)
        this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
      } catch (err) {
        apiToastWarning(err)
      }
    },
    onClickWishlistProduct(product) {
        this.$router.push({ name: 'product-detail', params: { id: product.productInfo._id, collectionId: product.productInfo.collectionId } })
        analytics.track(constants.TRACKS.PAGES.RETAILER_OPENS_WISHLIST_PRODUCT, formatObject(getSelectionOrWishlistMixpanelObj(product, false)))
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
    onCompactToggleClick() {
      if (this.itemViewClass === PRODUCTS_VIEW.GRID_VIEW) {
        this.itemViewClass = PRODUCTS_VIEW.COMPACT_GRID_VIEW
      }
      else {
        this.itemViewClass = PRODUCTS_VIEW.GRID_VIEW
      }
    },
    addToArray(product, groupIndex) {
      this.selectall = false
      this.show = true

      if (event.target.checked) {
        this.handleCheckedProduct(product, groupIndex)
        analytics.track(MULTI_SELECT_PRODUCTS.USER_SELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE, {
          product,
        })
      } else {
        this.handleUncheckedProduct(product)
        analytics.track(MULTI_SELECT_PRODUCTS.USER_DESELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE, {
          product,
        })
      }
    },
    handleCheckedProduct(product, groupIndex) {
      this.show = true
      this.productList.push(product)

      const groupIndexes = {
        groupIndex,
        productId: product.productId,
      }

      this.groupIndexes.push(groupIndexes)

      store.commit(UPDATE_SELECT_COUNT, this.selectCount + 1)

      if (this.isSelectedAllProducts) {
        this.handleSelectAllProducts(true, product)
      }
    },
    handleUncheckedProduct(product) {
      this.productList = this.productList.filter(p => p.productId !== event.target.value)
      this.groupIndexes = this.groupIndexes.filter(group => group.productId !== event.target.value)

      if (this.productList.length === 0) {
        this.show = false
      }

      store.commit(UPDATE_SELECT_COUNT, this.selectCount - 1)

      if (this.isSelectedAllProducts) {
        this.handleSelectAllProducts(false, product)
      }
    },
    handleSelectAllProducts(isChecked, product) {
      if (isChecked) {
        this.deselectedProducts = this.deselectedProducts.filter(p => p.productId !== event.target.value)
      } else {
        this.deselectedProducts.push(product)
      }
    },
    async removeAllFromWishList() {
      const action = wishlistAction.REMOVE_IN_WISHLIST
      await this.reactToggleProductInWishlist(this.productList, this.indexes, action)
      this.unSelectAll()
    },
    moveAllToCart() {
      this.addMultipleItemsCartActionClick(this.productList, this.indexes)
      this.unSelectAll()
    },
    unSelectAll() {
      this.productList = []
      this.indexes = []
      this.show = false
      this.$store.commit(UPDATE_SELECT_COUNT, 0)
      this.$store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
    },
    selectAll(select) {
      store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, select)
      this.deselectedProducts = []
      this.indexes = []
      this.productList = []
      this.groupIndexes = []
      if (select) {
        this.products.forEach((groupedProduct, index) => {
          groupedProduct.products.forEach(product => {
            this.indexes.push(product.productId)
            this.productList.push(product)
            const groupIndex = {
              groupIndex: index,
              productId: product.productId,
            }
            this.groupIndexes.push(groupIndex)
          })
        })
        store.commit(UPDATE_SELECT_COUNT, this.wishlist.count)
        this.show = true
      } else {
        this.show = false
        store.commit(UPDATE_SELECT_COUNT, 0)
      }
    },
  },

  setup(props, { root }) {
    const {
      parseProductImage,
      handleWishlistCartActionClick,
      toggleProductInWishlist,
      addMultipleItemsInCart,
    } = useEcommerceUi()
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const totalProducts = ref(0)
    const filters = ref({
      search: '',
      page: 0,
      limit: PRODUCTS_LIMIT,
    })
    const filterSetup = ref(false)
    const isLoaded = ref(false)
    const products = ref([])
    const filterOptions = ref({})
    let initialWholesalePriceMax
    let initialWholesalePriceMin
    let initialRetailPriceMax
    let initialRetailPriceMin
    const isWspHidden = ref(false)
    const requestStatus = ref(null)
    const groups = ref({})
    const selectedGroup = ref('None')
    let productsCount = 0

    const isLoading = ref(false)
    const loadingIcon = ref(null)
    const continueLoading = ref(true)
    const isSearchApplied = ref(false)
    const collectionId = ref(null)

    const deselectedProducts = ref([])
    const fetchQueryParams = ref({})

    const wishlist = ref({})
    const isReadOnly = ref(false)
    const isBrand = computed(() => root.$store.getters.isBrand)
    const wishlistId = computed(() => root.$route.params.wishlistId)
    const isSelectedAllProducts = computed(() => root.$store.state.shop.isSelectedAllProducts)
    const selectCount = computed(() => root.$store.state.shop.selectCount)
    const isPerformingAction = ref(false)
    const wishlistBaseCurrency = ref('')

    const trackVisits = data => {
      if (isBrand.value) {
        analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_SELECTION,
          formatObject(getSelectionOrWishlistMixpanelObj(data, isBrand.value)))
        analytics.track(constants.TRACKS.ACTIONS.BRAND_START_VIEWING_SELECTION, formatObject(getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value)))
          return
        }
        analytics.track(constants.TRACKS.PAGES.RETAILER_OPENS_WISHLIST_PRODUCTS,
          formatObject(getSelectionOrWishlistMixpanelObj(data, isBrand.value)))
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_START_VIEWING_WISHLIST, formatObject(getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value)))
    }

    const fetchWishlistProducts = (fetchParam, isFilterApplied = false) => {
      fetchParam.wishlistId = wishlistId.value
      root.$store
        .dispatch(FETCH_WISHLIST_PRODUCTS, fetchParam)
        .then(res => {
          const data = res?.data?.data
          root.$store.commit(UPDATE_TOTAL_PRODUCTS_COUNT, data.count)
          if (!isFilterApplied && !isSearchApplied.value) {
            trackVisits(data)
          }
          wishlist.value = data
          if (!fetchParam.currency) {
            wishlistBaseCurrency.value = wishlist.value?.products[0]?.products[0]?.productInfo?.currency
          }
          if (wishlist.value?.isReadOnly) {
            isReadOnly.value = wishlist.value?.isReadOnly
          }
          const currentWishlist = data
          store.commit(UPDATE_CURRENT_CHECKOUT_WISHLIST, currentWishlist)
          collectionId.value = data.collectionId
          isWspHidden.value = data.hasOwnProperty('isWspHidden') && data.isWspHidden
          requestStatus.value = (data.hasOwnProperty('connectionRequestStatus') && data.connectionRequestStatus) || null
          const result = data
          isLoaded.value = true
          if (filters.value.page === 0) {
            productsCount = 0
          }
          result.products.forEach(groupedProduct => {
            groupedProduct.products.forEach(product => {
              const productColors = []
              const colors = []
              productsCount++
              product.productInfo.items.forEach(item => {
                if (colors.indexOf(item.color) < 0) {
                  colors.push(item.color)
                  productColors.push({ color: item.color, colorFamily: item.colorFamily })
                }
              })
              product.colors = productColors
            })
          })
          totalProducts.value = result.count
          continueLoading.value = result.count > productsCount && result.products.length
          // append on loading by scroll
          if (filters.value.page >= 1) {
            if (result.products.length && products.value[products.value.length - 1]._id === result.products[0]._id) {
              products.value[products.value.length - 1].products = products.value[products.value.length - 1].products.concat(result.products[0].products)
              result.products.splice(0, 1)
            }
            products.value = [
              ...products.value,
              ...result.products,
            ]
          }
          else {
            products.value = result.products
          }
          isLoading.value = false
          root.$route.meta.breadcrumb = root.$route.meta.breadcrumb.map(item => {
            if (item.dynamic === true) {
              if (Object.prototype.hasOwnProperty.call(item, 'wishlistName')) {
                item.wishlistName = result.name
              }
            }
            return item
          })
        })
        .catch(err => {
          // wishlist not found
          apiToastError(err)
        })
      if (isFilterApplied || isSearchApplied.value) {
        analytics.track(isBrand.value ? constants.TRACKS.ACTIONS.BRAND_APPLIES_FILTER_SLN : constants.TRACKS.ACTIONS.RETAILER_APPLIES_FILTER_WLS, formatObject(fetchParam))
      }
      fetchQueryParams.value = fetchParam
    }
    // fetchShopProducts()
    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = newFilter => {
      let isFilterApplied = false
      if (newFilter && typeof newFilter === 'object') {
        isFilterApplied = true
        filters.value = newFilter
        filters.value.page = 0
        if (initialWholesalePriceMax === filters.value.wholesalePriceMax) delete filters.value.wholesalePriceMax
        if (initialWholesalePriceMin === filters.value.wholesalePriceMin) delete filters.value.wholesalePriceMin
        if (initialRetailPriceMax === filters.value.retailPriceMax) delete filters.value.retailPriceMax
        if (initialRetailPriceMin === filters.value.retailPriceMin) delete filters.value.retailPriceMin
      }
      const fetchParam = {
        page: filters.value.page,
      }
      for (const key in filters.value) {
        if (filters.value[key]) {
          if (Array.isArray(filters.value[key]) && filters.value[key].length) {
            fetchParam[key] = filters.value[key].join(',')
          }
          else {
            fetchParam[key] = filters.value[key]
          }
        }
      }
      fetchParam.groupBy = selectedGroup.value || 'None'
      fetchWishlistProducts(fetchParam, isFilterApplied)
    }

    const { removeProductFromWishlist } = useEcommerce()
    const removeProductFromWishlistClick = product => {
      removeProductFromWishlist(product.productInfo._id, wishlistId.value)
        .then(res => {
          apiToastSuccess(res.data.message || 'Removed product from wishlist!')
          products.value.forEach(groupedProduct => {
            const productIndex = groupedProduct.products.findIndex(p => p.productInfo._id === product.productInfo._id)
            if (productIndex > -1) {
              groupedProduct.products.splice(productIndex, 1)
            }
          })
          totalProducts.value -= 1
          if (totalProducts.value === 0) {
            filterOptions.value = []
          }
          analytics.track(
            isBrand.value ? constants.TRACKS.ACTIONS.BRAND_REMOVES_SELECTED_ITEMS_FROM_SELECTION : constants.TRACKS.ACTIONS.RETAILER_REMOVES_PRODUCTS_FROM_WISHLIST,
            formatObject(getSelectionOrWishlistMixpanelObj(wishlist.value, isBrand.value, { selectedProductsCount: 1 })),
          )
        })
      const fetchParam = {}
      fetchWishlistFilters(fetchParam)
    }

    const reactToggleProductInWishlist = async (product, index, action) => {
      isPerformingAction.value = true
      let updatedProduct = await toggleProductInWishlist({
        product, wishlistId: wishlistId.value, action, wishlist: wishlist.value,
      })
      store.commit(UPDATE_SELECT_COUNT, 0)
      updatedProduct = updatedProduct?.products || updatedProduct
      if (updatedProduct.length) {
        updatedProduct.forEach(updatedProductData => {
          products.value.forEach(groupedProduct => {
            const productIndex = groupedProduct.products.findIndex(
              p => p.productId === updatedProductData.productId,
            )
            if (productIndex > -1) {
              groupedProduct.products.splice(productIndex, 1)
            }
          })
        })
        totalProducts.value -= updatedProduct.length
        if (totalProducts.value === 0) {
          filterOptions.value = []
        }
      }
      isPerformingAction.value = false
    }

    const addMultipleItemsCartActionClick = async (product, index) => {
      let deselectedProductIds = []
      let filterQueries = {}
      if (isSelectedAllProducts.value) {
        fetchQueryParams.value.isSelectedAllProducts = true
        fetchQueryParams.value.collectionId = collectionId.value
        filterQueries = fetchQueryParams.value
        deselectedProductIds = deselectedProducts.value?.map(p => p.productId)
      }
      const updatedProduct = await addMultipleItemsInCart(product, filterQueries, deselectedProductIds)
      store.commit(UPDATE_SELECT_COUNT, 0)
      if (Array.isArray(updatedProduct)) {
        updatedProduct.forEach((updatedProductData, ind) => {
          Vue.set(products.value, index[ind], updatedProductData)
        })
      }
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_MOVES_FROM_WISHLIST_TO_CART, formatObject(getSelectionOrWishlistMixpanelObj(wishlist.value, false)))
    }

    // setup filters
    const fetchWishlistFilters = async fetchParam => {
      if (wishlistId.value) {
        fetchParam.wishlistId = wishlistId.value
        await root.$store
          .dispatch(FETCH_WISHLIST_FILTERS, fetchParam)
          .then(res => {
            filterOptions.value = res.data.data[0] || {}
            for (const key in filterOptions.value) {
              if (filterOptions.value.hasOwnProperty(key)) {
                filters.value[key] = []
                if (key.indexOf('PriceMax') > -1) {
                  filterOptions.value[key] = Math.round(filterOptions.value[key]) + 1
                }
                if (key.indexOf('PriceMin') > -1) {
                  filterOptions.value[key] = Math.round(filterOptions.value[key]) - 1
                }
              }
            }
            filterOptions.value.retailPrice = [filterOptions.value.retailPriceMin, filterOptions.value.retailPriceMax]
            filterOptions.value.wholesalePrice = [filterOptions.value.wholesalePriceMin, filterOptions.value.wholesalePriceMax]
            initialWholesalePriceMax = filterOptions.value.wholesalePriceMax
            initialWholesalePriceMin = filterOptions.value.wholesalePriceMin
            initialRetailPriceMax = filterOptions.value.retailPriceMax
            initialRetailPriceMin = filterOptions.value.retailPriceMin
            groups.value = filterOptions.value.groupBy
            filterSetup.value = true
          })
      }
    }

    if (!filterSetup.value) {
      const fetchParam = {
        page: 0,
      }
      fetchWishlistFilters(fetchParam)
    }

    const filterByCurrency = (currency = '') => {
      if (!currency) {
        delete filters.value?.currency
      } else {
        filters.value.currency = currency
        filters.value.page = 0
      }
      fetchShopProducts()
    }

    const searchProducts = debounce(() => {
      isSearchApplied.value = true
      filters.value.page = 0
      fetchShopProducts()
    }, 1000)

    watch([selectedGroup], () => {
      filters.value.page = 0
      fetchShopProducts()
    })
    const closeSideBar = () => {
      mqShallShowLeftSidebar.value = false
    }
    const onScrollLoader = () => {
      if (!isLoading.value && elementInViewport(loadingIcon.value)) {
        isLoading.value = true
        filters.value.page++
        fetchShopProducts()
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
    })

    // wishlist actions
    return {
      wishlist,
      isLoaded,
      products,
      wishlistId,
      notFoundImg: require('@/assets/images/pages/error.svg'),
      // UI
      addMultipleItemsCartActionClick,
      parseProductImage,
      handleWishlistCartActionClick,
      removeProductFromWishlistClick,
      toggleProductInWishlist,
      reactToggleProductInWishlist,
      addMultipleItemsInCart,
      formatCurrency,
      mqShallShowLeftSidebar,
      totalProducts,
      filters,
      filterOptions,
      filterSetup,
      isWspHidden,
      requestStatus,
      fetchShopProducts,
      closeSideBar,
      groups,
      selectedGroup,
      continueLoading,
      loadingIcon,
      searchProducts,
      collectionId,
      isReadOnly,
      isSelectedAllProducts,
      selectCount,
      deselectedProducts,
      isPerformingAction,
      filterByCurrency,
      wishlistBaseCurrency,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/base/pages/app-shop.scss';

.content.app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 1rem 0;
}
</style>
